import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Deposit from "./pages/Deposit";
import Deposithistory from "./pages/Deposithistory";
import Profithistory from "./pages/Profithistory";
import Directprofit from "./pages/directprofit";
import Binaryprofit from "./pages/Binaryprofit";


import Withdraw from "./pages/Withdraw";
import Internaltransfer from "./pages/Internal-transfer";

import P2p from "./pages/P2p";
import Treeview from "./pages/Treeview";
import Swap from "./pages/Swap";
import Staking from "./pages/Staking";
import Settings from "./pages/Settings";
import ForgotPassword from "./pages/ForgotPassword";
import Swaphistory from "./pages/swaphistory";
import Tfaverify from "./pages/Tfa-verify";
import CreateTfa from "./pages/Create-tfa";

import TeamA from "./pages/TeamA";
import TeamB from "./pages/TeamB";





import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "../src/core/lib/localStorage";
import "./App.css";
import "./style.css";

function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-2fa" element={<Tfaverify />} />
          <Route path="/create-2fa" element={<CreateTfa />} />


          <Route path="/dashboard" element={
            <RequireAuth>
              {" "}
              <Dashboard />{" "}
            </RequireAuth>} />
          <Route path="/deposit" element={<RequireAuth>
            {" "}
            <Deposit />{" "}
          </RequireAuth>} />


          <Route path="/internaltransfer" element={<RequireAuth>
            {" "}
            <Internaltransfer />{" "}
          </RequireAuth>} />
          <Route path="/deposithistory" element={<RequireAuth>
            {" "}
            <Deposithistory />{" "}
          </RequireAuth>} />
          <Route path="/withdraw" element={<RequireAuth>
            {" "}
            <Withdraw />{" "}
          </RequireAuth>} />
          <Route path="/p2p" element={<RequireAuth>
            {" "}
            <P2p />{" "}
          </RequireAuth>} />
          <Route path="/treeview" element={<RequireAuth>
            {" "}
            <Treeview />{" "}
          </RequireAuth>} />
          <Route path="/swap" element={<RequireAuth>
            {" "}
            <Swap />{" "}
          </RequireAuth>} />
          <Route path="/staking" element={<RequireAuth>
            {" "}
            <Staking />{" "}
          </RequireAuth>} />
          <Route path="/settings" element={<RequireAuth>
            {" "}
            <Settings />{" "}
          </RequireAuth>} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/swaphistory" element={<RequireAuth>
            {" "}
            <Swaphistory />{" "}
          </RequireAuth>} />

          <Route path="/profithistory" element={<RequireAuth>
            <Profithistory />{" "}
          </RequireAuth>} />

          <Route path="/binaryhistory" element={<RequireAuth>
            <Binaryprofit />{" "}
          </RequireAuth>} />

          <Route path="/directhistory" element={<RequireAuth>
            <Directprofit />{" "}
          </RequireAuth>} />

          <Route path="/teamA" element={<RequireAuth>
            <TeamA />{" "}
          </RequireAuth>} />

          <Route path="/teamB" element={<RequireAuth>
            <TeamB />{" "}
          </RequireAuth>} />


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
