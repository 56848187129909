import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../src/App.css";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Header from "./Header";

export default function Register() {


  const initialFormValue = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
    position: "",
    mobile: "",
    // country: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility

  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [
    mobileValidate,
    setmobileValidate,
    mobileValidateref,
  ] = useState(false);

  const [transpasswordValidate, settranspasswordValidate, transpasswordValidateref] =
  useState(false);
const [
  confirmTransValidate,
  setconfirmTransValidate,
  confirmTransValidateref,
] = useState(false);

  const [isChecked, setIschecked] = useState(false);
  const [Registered, setRegistered] = useState({});

  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [countryOptions, setCountryOptions] = useState(countryList().getData());
  const [usernameValidate, setusernameValidate, usernameValidateref] =
    useState(false);

  const [transpassHide, settransPasshide] = useState(false);
  const [transinputType, settransinputType] = useState("password");

  const [transpassHidconf, settransPasshideconf] = useState(false);
  const [transinputTypeconf, settransinputTypeconf] = useState("password");

 

  const {
    username,
    email,
    password,
    confirmPassword,
    referral_code,
    position,
    mobile,
    country,
    Transactionpassword,
    confirmTransactionpassword,
  } = formValue;
  const [siteLoader, setSiteLoader] = useState(false);

  const navigate = useNavigate();

  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handlePhoneChange = (value) => {

    console.log(value, "value")
    setFormValue({ ...formValue, mobile: value });
    validate({ ...formValue, mobile: value });
  };

  // const handleCountryChange = (selectedOption) => {
  //   setFormValue({ ...formValue, country: selectedOption.value });
  //   validate({ ...formValue, country: selectedOption.value });
  // };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = (values) => {
    let errors = {};
    const phoneNumberRegex = /^\+?(\d{1,4})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/;
    if (!values.username) {
      setusernameValidate(true);
      errors.username = "Username is required!";
    } else if (values.username.length < 4 || values.username.length > 10) {
      setusernameValidate(true);
      errors.username = "Name should be between 4 and 10 characters!";
    } else if (!values.email) {
      errors.email = "Email is a required field!";
      setusernameValidate(false);
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (!values.password) {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required!";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = "Password should be between 5 and 25 characters!";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one lowercase character!";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one uppercase character!";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one digit!";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character!";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm password is a required field!";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and confirm password do not match!";
      setconfirmPasswordValidate(true);
    } 
    else if (!values.Transactionpassword) {
      setconfirmPasswordValidate(false);
      settranspasswordValidate(true);
      errors.Transactionpassword = "Transaction Password is required!";
    } else if (values.Transactionpassword.length < 5 || values.Transactionpassword.length > 25) {
      settranspasswordValidate(true);
      errors.Transactionpassword = "Transaction Password should be between 5 and 25 characters!";
    } else if (!values.Transactionpassword.match(/[a-z]/g)) {
      settranspasswordValidate(true);
      errors.Transactionpassword = "Please enter at least one lowercase character!";
    } else if (!values.Transactionpassword.match(/[A-Z]/g)) {
      settranspasswordValidate(true);
      errors.Transactionpassword = "Please enter at least one uppercase character!";
    } else if (!values.Transactionpassword.match(/[0-9]/g)) {
      settranspasswordValidate(true);
      errors.Transactionpassword = "Please enter at least one digit!";
    } else if (!values.Transactionpassword.match(/[!@#$%^&*]/g)) {
      settranspasswordValidate(true);
      errors.Transactionpassword = "Please enter at least one special character!";
    } else if (!values.confirmTransactionpassword) {
      settranspasswordValidate(false);
      errors.confirmTransactionpassword = "Confirm Transaction password is a required field!";
      setconfirmTransValidate(true);
    } else if (
      values.Transactionpassword &&
      values.confirmTransactionpassword &&
      values.Transactionpassword !== values.confirmTransactionpassword
    ) {
      errors.confirmTransactionpassword = "Transaction Password and confirm password do not match!";
      setconfirmTransValidate(true);
    } 
    
    else if (!values.mobile) {
      setmobileValidate(true);
      errors.mobile = "Mobile number is a required field!";
      // } else if (!values.country) {
      //   errors.country = "Country is a required field!";
    } else if(!phoneNumberRegex.test(values.mobile))
    {
      setconfirmTransValidate(false);
      setmobileValidate(true);
      errors.mobile = "Mobile number is a required field!";
    }
     else if (!Terms) {
      setmobileValidate(false);
      errors.terms = "Terms and conditions acceptance is required!";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setconfirmTransValidate(false);
      setpasswordValidate(false);
      setemailValidate(false);

      setTermsValidation(false);
      setusernameValidate(false);
    }
    setvalidationnErr(errors);

    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };
  // setModalVisible(true);

  const transpasswordHide = (data) => {
    if (data == "hide") {
      settransPasshide(true);
      settransinputType("text");
    } else {
      settransPasshide(false);
      settransinputType("password");
    }
  };

  const transpasswordHideconf = (data) => {
    if (data == "hide") {
      settransPasshideconf(true);
      settransinputTypeconf("text");
    } else {
      settransPasshideconf(false);
      settransinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    let errors = validate(formValue);
    if (
      usernameValidateref.current === false &&
      emailValidateref.current === false &&
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false &&
      mobileValidateref.current === false &&
      transpasswordValidateref.current === false &&
      confirmTransValidateref.current === false &&
      Terms == true
    ) {
      // if (recaptcha) {
        formValue["UUID"] = createdUuid;
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        // console.log("register form ===",formValue);
        // return;
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        if (resp.status) {
          // toast.success(resp.Message);
          // navigate("/login");
          setRegistered(resp.data);

          setModalVisible(true);


        } else {
          toast.error(resp.Message);
        }
      // } else {
      //   toast.error("Recaptcha not verified");
      // }
    } else {
      console.log("required all field");
    }
  };

  const handleLoginNavigation = () => {
    setModalVisible(false);
    navigate("/login");
  };


  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState("");
  const [dropData, setDropData] = useState([]);

  useEffect(() => {
    var currURL = window.location.href;
    let result = currURL.includes("?");
    if (result) {
      var getUserName = currURL.split("username=")[1].split("&")[0];
      var getPosition = currURL.split("position=")[1];
      setSelectedOption(getPosition);

      formValue.position = (getPosition == "L") ? "Left" : "Right";
      formValue.referral_code = getUserName;

      if (getPosition == "L") {
        const data = [
          {
            value: 1,
            label: "A Team",
          },
        ];
        setSelectedOption(data[0]);
        setDropData(data);
      } else if (getPosition == "R") {
        const data = [
          {
            value: 2,
            label: "B Team",
          },
        ];
        // setSelectedOption("Right");
        setSelectedOption(data[0]);
        setDropData(data);
      }
    } else {
      navigate("/");
      // const data = [
      //   {
      //     value: 1,
      //     label: "Left",
      //   },
      //   {
      //     value: 2,
      //     label: "Right",
      //   },
      // ];
      // setDropData(data);
    }
  }, [0]);

  const handleChangeDrop = (e) => {
    formValue.position = e.label;
    setSelectedOption(e);
    console.log(e.label, "=-=-==-=-=-=selectedOption==-==-=");
  };

  const [recaptcha, setrecaptcha] = useState("");

  const onChange = (value) => {
    var captcha = value;
    if (captcha) {
      setrecaptcha(captcha);
    } else {
      toast.error("Captcha not verified");
    }
  };

  return (
    <div>
      <Header />
      <div className="register-one">
        <div className="container">
          <div className="row register-form justify-content-center reg_row">
            <div className="col-lg-5 mt-2">
              <div className="reg_right_main">
                <div className="reg_left_head">
                  Welcome to Wonderful Xdream Life
                </div>
                <div className="p">Register with your email</div>

                <div className="reg_in_main">
                  <div className="reg_in_top">
                    <div className="reg_infields">
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Name</h5>
                        <input
                          type="text"
                          name="username"
                          value={username}
                          onChange={handleChange}
                          minLength={4}
                          maxLength={10}
                          className="reg_inp_field"
                          placeholder="Enter your Name"
                        />
                        {validationnErr && validationnErr.username && (
                          <p className="errorcss">{validationnErr.username}</p>
                        )}
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Email</h5>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          className="reg_inp_field"
                          placeholder="Enter your email"
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Password</h5>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={6}
                            maxLength={25}
                            onChange={handleChange}
                            className="reg_inp_field"
                            placeholder="Enter your password"
                          />

                          {passHide == true ? (
                            <i
                              className="fa-regular fa-eye reg_eye"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash reg_eye"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">
                          Confirm Password
                        </h5>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={6}
                            maxLength={25}
                            onChange={handleChange}
                            className="reg_inp_field"
                            placeholder="Enter confirm password"
                          />{" "}
                          {passHidconf == true ? (
                            <i
                              className="fa-regular fa-eye reg_eye"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash reg_eye"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}
                      </div>

                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Transaction Password</h5>
                        <div className="flex_input_posion">
                          <input
                            type={transinputType}
                            name="Transactionpassword"
                            value={Transactionpassword}
                            minLength={6}
                            maxLength={25}
                            onChange={handleChange}
                            className="reg_inp_field"
                            placeholder="Enter your Transaction password"
                          />

                          {transpassHide == true ? (
                            <i
                              className="fa-regular fa-eye reg_eye"
                              onClick={() => transpasswordHide("show")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash reg_eye"
                              onClick={() => transpasswordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.Transactionpassword && (
                          <p className="errorcss">{validationnErr.Transactionpassword}</p>
                        )}
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">
                          Confirm Transaction Password
                        </h5>
                        <div className="flex_input_posion">
                          <input
                            type={transinputTypeconf}
                            name="confirmTransactionpassword"
                            value={confirmTransactionpassword}
                            minLength={6}
                            maxLength={25}
                            onChange={handleChange}
                            className="reg_inp_field"
                            placeholder="Enter Confirm Transaction Password"
                          />{" "}
                          {transpassHidconf == true ? (
                            <i
                              className="fa-regular fa-eye reg_eye"
                              onClick={() => transpasswordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye-slash reg_eye"
                              onClick={() => transpasswordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmTransactionpassword && (
                          <p className="errorcss">
                            {validationnErr.confirmTransactionpassword}
                          </p>
                        )}
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Mobile Number</h5>
                        <PhoneInput
                          country={"us"}
                          value={mobile}
                          onChange={handlePhoneChange}
                          inputStyle={{ width: "100%" }}
                        />
                        {validationnErr && validationnErr.mobile && (
                          <p className="errorcss">{validationnErr.mobile}</p>
                        )}
                      </div>
                      {/* <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">Country</h5>
                        <Select
                          options={countryOptions}
                          value={countryOptions.find(
                            (option) => option.value === country
                          )}
                          onChange={handleCountryChange}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                              ...theme.colors,
                              primary25: "purple",
                              primary: "pueple",
                            },
                          })}
                        />
                        {validationnErr && validationnErr.country && (
                          <p className="errorcss">{validationnErr.country}</p>
                        )}
                      </div> */}
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">
                          Referral Code
                        </h5>
                        <input
                          type="text"
                          readOnly
                          name="referral_code"
                          value={referral_code}
                          onChange={handleChange}
                          className="reg_inp_field"
                          placeholder="Enter referral code"
                        />
                      </div>
                      <div className="req_field_sin">
                        <h5 className="earn_divs_insubhead">
                          Position (Optional)
                        </h5>
                        <Select
                          name="Position"
                          readonly
                          onChange={handleChangeDrop}
                          options={dropData}
                          value={selectedOption}
                          className="form-control_newsect"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                              ...theme.colors,
                              primary25: "purple",
                              primary: "pueple",
                            },
                          })}
                        />
                      </div>
                      <div className="terms_condition_div ">
                        <input
                          className="form-check-input"
                          id="checkbox"
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleTerms}
                        />
                        <label className="terms_condition">
                          I agree to the{" "}
                          <Link to="/terms-conditions">
                            Terms and Conditions
                          </Link>
                        </label>
                      </div>{" "}
                      {termsValidation && (
                        <p className="errorcss terms_req">
                          Accepting terms is required!
                        </p>
                      )}
                      {/* <div className="terms_condition_div">
                        <ReCAPTCHA
                          sitekey="6Len9TAqAAAAAJXTZ7WyfrQSBhUBROenmBvkghVC"
                          onChange={onChange}
                          className="recaptcha"
                          theme="dark"
                        />
                      </div> */}
                      <div className="">
                        <div
                          onClick={formSubmit}
                          disabled={buttonLoader}
                          className="template-btn btn-style-one d-flex justify-content-center"
                        >
                          <span className="btn-wrap">
                            <span className="text-one">
                              {buttonLoader ? "Registering..." : "Register"}
                            </span>
                            <span className="text-two">
                              {buttonLoader ? "Registering..." : "Register"}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="newacc text-center mt-3">
                        Already have an account?{" "}
                        <Link to="/login">
                          <span>Log In</span>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {modalVisible == true && (
        <div className="modal fade bd-example-modal-md" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content select_coin">
              <div className="modal-header kyc_modal_header">
                <img
                  src={require('../assets/images/dash_icon/tick.webp')}
                  className="d-block mx-auto"
                  width="100px"
                  alt="Success"
                />
                <h6>
                  Thank you for registering for WXDL. <br />
                  Your registered name is <span>Mugeshkumar</span>. Password is <span>Mugesh@123</span> and User ID is <span>UKLNWXDL</span>, and mobile number is <span>919876543210</span>.
                </h6>
                <h5>Registration Successfully</h5>
                <h4>
                  <button onClick={handleLoginNavigation}>
                    Go to login
                  </button>
                </h4>
              </div>
            </div>
          </div>
        </div>
      )} */}

            <Modal isOpen={modalVisible} className="modalss" toggle={() => setModalVisible(false)}>
              <ModalBody>
                <div className="modal-header kyc_modal_header">
                  <img
                    src={require('../assets/images/dash_icon/tick.webp')}
                    className="d-block mx-auto"
                    width="100px"
                    alt="Success"
                  />
                  <h6>
                    Thank you for registering for WXDL. <br />
                    Your registered name is <span>{Registered.name}</span>. Password is <span> {Registered.password} </span> and User ID is <span>{Registered.id}</span>.
                  </h6>
                  <h5>Registration Successfully</h5>
                  <h4>
                    <button onClick={handleLoginNavigation}>
                      Go to login
                    </button>
                  </h4>
                </div>
              </ModalBody>
            </Modal>


          </div>
        </div>
      </div>
    </div>
  );
}
