import React, { useState, useEffect } from "react";
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import InnerHeader from "./Header";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

const Swap = () => {
    const [fromOption, setFromOption] = useState("withdraw");
    const [toOption, setToOption] = useState("p2p");
    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState({ p2p: 0, amount: 0 });

    useEffect(() => {
        getUserBalance();
    }, []);

    const getUserBalance = async () => {
        const data1 = { apiUrl: apiService.getUserBalanceInternaltransfer };
        const resp = await getMethod(data1);
        if (resp.status) {
            setBalance(resp.data);
        }
    };

    const handleTransfer = async () => {
        var obj = {
            fromWallet: fromOption,
            toWallet: toOption,
            amount,
        };

        var data = {
            apiUrl: apiService.handletransfer,
            payload: obj,
        };

        const response = await postMethod(data);
        if (response.status) {
            setAmount(0);
            toast.success("Transfer successful");
            getUserBalance(); // Refresh balance
        } else {
            setAmount(0);
            toast.error(response.Message);
        }
    };

    const options = [
        { key: "p2p", text: "P2P Wallet", value: "p2p" },
        { key: "withdraw", text: "Smart Wallet", value: "withdraw" },
    ];

    // Remove handleFromChange function
    const handleToChange = (e, { value }) => {
        setToOption(value);
    };

    const [depositHistory, setDepositHistory] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const navigate = useNavigate();

    const fetchDepositHistory = async () => {
        try {
            const response = await postMethod({
                apiUrl: apiService.intransfer_history,
                payload: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                },
            });

            if (response.success) {
                setDepositHistory(response.data.data);
                setTotalPages(response.data.pages);
            } else {
                setDepositHistory([]);
                setTotalPages(0);
            }
        } catch (error) {
            console.error("Error fetching deposit history:", error);
            toast.error("Failed to fetch data.");
        }
    };

    useEffect(() => {
        fetchDepositHistory();
    }, [currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const convertUTCToIST = (utcDateString) => {
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          };
        
          const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
        
          return istDate;
      };

    return (
        <>
            <div className="inner_header">
                <InnerHeader />
            </div>
            <div className="swap_main p2p_table_content">
                <div className="container">
                    <div className="">
                        <p className="titlr_lable_dd text-start">
                            <a href="/" className="button_home">
                                <span>Home</span>
                            </a>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            <small>Swap</small>
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 wxdl_deposit mt-5">
                            <div className="swap_pay_card mb-3">
                                <div className="your_pay_text">
                                    <h4>You Pay</h4>
                                    <p>
                                        Balance: {balance.amount.toFixed(4)}
                                    </p>
                                </div>
                                <div className="your_pay_amt_text">
                                    <h3>
                                        <input
                                            type="number"
                                            min="0"
                                            value={amount}
                                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                                        />
                                    </h3>
                                    <Dropdown
                                        inline
                                        placeholder="Select Wallet"
                                        options={options.filter(option => option.value === "withdraw")}
                                        value={fromOption}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="swap_pay_card swap_pay_card2 my-3 mb-5">
                                <div className="your_pay_text">
                                    <h4>You Get</h4>
                                    <p>
                                        Balance: {balance.p2p.toFixed(4)}
                                    </p>
                                </div>
                                <div className="your_pay_amt_text">
                                    <h3>
                                        <input
                                            type="number"
                                            disabled
                                            value={amount}
                                        />
                                    </h3>
                                    <Dropdown
                                        inline
                                        placeholder="Select Wallet"
                                        options={options.filter(option => option.value === "p2p")}
                                        value={toOption}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="withdrawal_button mt-3">
                                <button onClick={handleTransfer}>
                                    <span className="land-sign-letter">Transfer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dashboard_table_content container mt-5">
                    <div className="deposit_history_content">
                        <h3 className="referral_title">Internal Transfer History</h3>
                    </div>
                    <div className="table_overflow">
                        <table className="wxdl_dashboard table">
                            <thead>
                                <tr>
                                    <th scope="col" className="start">SI.No</th>
                                    <th scope="col">From Wallet</th>
                                    <th scope="col">To Wallet</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Fees</th>
                                    <th scope="col" className="end">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depositHistory.length > 0 ? (
                                    depositHistory.map((item, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i + 1}</th>
                                            <td>{item.fromCurrency}</td>
                                            <td>{item.toCurrency}</td>
                                            <td>{parseFloat(item.totalAmount).toFixed(4)}</td>
                                            <td> { convertUTCToIST(item.createdDate) }</td>
                                            <td>{item.fee.toFixed(2)}</td>
                                            <td className="text-green">Success</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className="background_trans">
                                            <div className="empty_data">
                                                <div className="no_records_text">No Records Found</div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {totalPages > 1 && (
                        <div className="pagination">
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                className="table_pagination"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Swap;
